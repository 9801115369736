<template>
  <div id="school-class-attendance-info" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-right">
        {{ schoolClassName }}, {{ month }}
      </p>
      <b-table :data="attendances" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          attendances.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column label="NP" v-slot="props">{{
          props.row.total_number_of_times_present
        }}</b-table-column>

        <b-table-column label="NA" v-slot="props">{{
          props.row.total_number_of_times_absent
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row.studentId)"
            :show-update="false"
            :show-remove="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  name: 'SchoolClassAttendanceInfo',
  data() {
    return {
      schoolId: null,
      pageTitle: 'School Class Attendance Info',
      attendances: [],
      schoolClassAttendance: [],
      schoolClassId: null,
      schoolClassName: null,
      month: null,
    }
  },
  watch: {
    schoolClassAttendance(data) {
      this.attendances = []
      this.schoolClassName = data.name
      data.studentAttendances.forEach((attendance) => {
        const [student_attendance] = attendance.attendances.filter(
          (item) => item.month === this.month
        )
        this.attendances.push({
          studentId: attendance.student_id,
          name: attendance.name,
          total_number_of_times_present: student_attendance.total,
          total_number_of_times_absent:
            student_attendance.days_in_month - student_attendance.total,
        })
      })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = parseInt(this.$route.params.id)
    this.month = this.$route.params.month
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Attendances',
        route: `/school/${this.schoolId}/attendances`,
      },
      {
        name: 'Attendance Info',
        route: `/school/${this.schoolId}/attendance_info/${this.schoolClassId}`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = term.id

      this.$apollo.addSmartQuery('schoolClassAttendance', {
        query: gql`
          query SchoolClassStudentsAttendanceQuery($id: ID!, $termId: ID!) {
            schoolClassAttendance(id: $id, termId: $termId) {
              name
              studentAttendances
            }
          }
        `,
        variables: {
          id: parseInt(this.schoolClassId),
          termId: parseInt(this.termId),
        },
      })

      this.$apollo.queries.schoolClassAttendance.refetch()
    })
  },
  methods: {
    initializeInfo(studentId) {
      this.$router.push(
        `/school/${this.schoolId}/student_attendance_info/${studentId}/school_class/${this.schoolClassId}/month/${this.month}`
      )
    },
  },
}
</script>
